import Headline from "../components/Headline";
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import {Navigation} from "swiper";
import {Pagination} from "swiper";
import {useState} from "react";


export default function Home() {

    const [data, setData] = useState<any>(null)

    fetch("./images.json").then(
        function(res){
            return res.json()
        }).then(function(data){
        // store Data in State Data Variable
        setData(data)
    }).catch(
        function(err){
            console.log(err, ' error')
        }
    )

    return (
        <div className="home">


            <div id={"home"} className={"home__start"}>
                <h1>
                    Urlaub bei Dörflingers
                </h1>
                <img src={"./images/home.png"}/>
            </div>


            <div id={"buehl"} className={"home__buehl"}>
                <div className={"home__card container"}>
                    <Headline name={"Bühl"} desc={"Ort & Umgebung"}/>

                    <p>
                        Sie finden uns in Klettgau im Ortsteil Bühl direkt an der Grenze zur Schweiz.
                        In unserem schönen Dorf leben rund 270 Menschen und etwa 100 Pferde.
                        Die Landschaft lädt zum Spazieren gehen, Wandern, Radfahren oder zu einem Ausritt ein.
                        Ausflüge zu beliebten Zielen wie nach Zürich, Schaffhausen oder an den Rheinfall,
                        sowie an den Bodensee, die Insel Mainau oder einer Tagestour in den Schwarzwald
                        bieten sich an.
                    </p>
                </div>
            </div>

            <div id={"wohnung"} className={"home__card__container"}>
                <div className={"home__card home__card-background container"}>
                    <Headline name={"Wohnung"} desc={"Unser Goldstück"} white/>

                    <p>
                        Unsere Nichtraucherwohnung hat 90qm Wohnfläche und ist für zwei bis vier Personen geeignet.
                        Die Wohnung verfügt über eine vollständig ausgestattete Küche mit Geschirrspüler und ein großes
                        Esszimmer. Ein Schlafzimmer mit Doppelbett und großem Schiebetürenschrank sowie ein Schlafzimmer
                        mit zwei getrennten Betten, einen Schreibtisch; ein gemütliches Wohnzimmer mit Sofa und
                        „Chuscht“
                        auch Kachelofen genannt. Ein Badezimmer mit Dusche, Badewanne und WC ist natürlich auch dabei.
                        Im Garten kann man auf dem Bänkle am Teich herrlich entspannen. Eine Möglichkeit zum
                        Unterstellen
                        von Fahrrädern ist vorhanden.
                    </p>
                </div>
                <img src={"./images/wohnung.png"}/>

            </div>

            <div>
                <Swiper navigation={true} modules={[Navigation, Pagination]}>
                    {data ? data.map((_image : string, _index: number) => {
                        return (
                            <SwiperSlide>
                                <img alt={_index + ""} src={"./images/" + _image}/>
                            </SwiperSlide>
                        )
                    }) : <SwiperSlide><div>Loading images...</div></SwiperSlide>}
                </Swiper>
            </div>

            <div id={"preis"} className="home__price">
                <div className={"home__card container"}>
                    <Headline name={"Preise"} desc={"Infos & Preise"}/>
                    <div className="home__price__inner">
                        <dl>
                            <dt>Bis zu zwei Personen:</dt>
                            <dd>75,00 Euro pro Nacht</dd>
                        </dl>
                        <dl className={"home__price__special"}>
                            <dt>Weitere erwachsene Person:</dt>
                            <dd>15,00 Euro pro Nacht</dd>
                        </dl>
                        <dl className={"home__price__special"}>
                            <dt>Kinder:</dt>
                            <dd>10,00 Euro pro Nacht</dd>
                        </dl>
                        <span><p>*Endreinigung 70,00 Euro</p></span>
                        <dl>
                            <dt>Unterstellplatz für Fahrräder:</dt>
                            <dd>10,00 Euro pro Woche</dd>
                        </dl>
                        <dl>
                            <dt>Sie möchten Ihr/e Pferd/e mitbringen?</dt>
                        </dl>
                        <dl>
                            <dt>Pro Pferd:</dt>
                            <dd>20,00 Euro pro Tag</dd>
                        </dl>
                        <span><p>*Mit Rücksichtnahme auf Menschen mit Allergien können wir Haustiere nicht beherbergen.</p></span>
                        <dl>
                            <dt>Bei einem längerfristigen Aufenthalt in unserer Wohnung, bei Wohnen auf Zeit, erhalten
                                Sie auf
                                schriftliche Anfragen ein Angebot.
                            </dt>
                        </dl>
                    </div>
                </div>
            </div>

        </div>
    )
}

import {GiHamburgerMenu} from 'react-icons/gi'
import {useState} from "react";


const PAGES = [
    {
        name: 'Home',
        href: '/#home'
    },
    {
        name: 'Bühl',
        href: '/#buehl'
    },
    {
        name: 'Wohnung',
        href: '/#wohnung'
    },
    {
        name: 'Preise',
        href: '/#preis'
    },
    {
        name: 'Kontakt',
        href: '/kontakt'
    },
]


export default function Header() {

    const [open, setOpen] = useState(false)

    return (
        <header className={"header__wrapper"}>
            <nav className={"header__wrapper__inner container"}>
                <img src={"./logo.png"} alt={"logo"}/>

                <ul>
                    {PAGES.map((_object, _index) => {
                        return (
                            <li>
                                <a href={_object.href}>{_object.name}</a>
                            </li>
                        )
                    })}
                </ul>

                <GiHamburgerMenu onClick={() => setOpen(!open)}/>
            </nav>

            {open && <div className="header__dropdown">
                <ul>
                    {PAGES.map((_object, _index) => {
                        return (
                            <li>
                                <a href={_object.href}>{_object.name}</a>
                            </li>
                        )
                    })}
                </ul>
            </div>}
        </header>
    )
}
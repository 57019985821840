import React from 'react';
import Header from './components/Header'
import {Route, Routes} from "react-router-dom";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import Footer from './components/Footer'

function App() {
    return (
        <>
            <Header/>
            <Routes>
                <Route index element={<Home/>}/>
                <Route path={"kontakt"} element={<Contact/>}/>
            </Routes>
            <Footer/>
        </>
    );
}

export default App;

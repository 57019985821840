export default function Footer() {
    return (
        <footer>
            <h1>
                Ferienwohnung Klettgau Buehl
            </h1>
            <p>
                © 2022 Bühl Ferienwohnung | Stettener Str. 9 | 79771 Klettgau
            </p>
            <a href={"/kontakt"}>Impressum</a>
        </footer>
    )
}
export default function Contact() {
    return (
        <div className={"contact"}>
            <div className="contact__credentials">
                <p>
                    Alois und Angelika Dörflinger
                    <br/><br/>
                    Stettener Str. 9
                    <br/><br/>
                    79771 Klettgau
                    <br/><br/>
                    Telefon 0049 7742 7934<br/>
                    <span>(gerne auf den AB sprechen)</span>
                    <br/><br/>
                    Fax 0049 7742 9712618
                    <br/><br/>
                    Email urlaub-bis-doerflingers@web.de
                </p>
            </div>

            <div className={"contact__design"}>
                <div>
                <h1>Kontaktiere <br/>
                    –––––– uns!</h1>
                <p>
                    Uns ist es wichtig in Verbindung
                    mit Ihnen zu bleiben, wir sind
                    jederzeit bereit Ihre Fragen zu
                    beantworten.
                </p>
                </div>
            </div>
        </div>
    )
}